<template>
  <div class="head_top">
    <img src="../../../../../public/images/头部导航二级页面/wifi摄像机/室外摄像机/index室外机.jpg" width="100%" height="100%" @click="">
    <!--    室外云台摄像机（500万像素）-->
    <div class="content1">
      <div class="content1_title">
        <div class="content1_title_size">室外云台摄像机（500万像素）</div>
      </div>
      <div class="grid" id="grid">
        <PhotoCard img="images/相机详情/二级页面产品白底图150/室外机-2/500万像素/室外高清wifi机Z86Q.jpg" names="Z86Q" ulr="/Z86Q"></PhotoCard>
        <PhotoCard img="images/相机详情/二级页面产品白底图150/室外机-2/500万像素/Z60Q室外高清wifi机.jpg" names="Z60Q" ulr="/Z60Q"></PhotoCard>
        <PhotoCard img="images/相机详情/1.WIFI摄像机/室外机详情页（500万像素）/Z85-X18/Z85-X18.jpg" names="Z85-X18" ulr="/Z85-X18"></PhotoCard>
      </div>
    </div>

    <!--    室外云台摄像机（300万像素）-->
    <div class="content2">
      <div class="content1_title">
        <div class="content1_title_size">室外云台摄像机（300万像素）</div>
      </div>
      <div class="grid" id="grid">
        <PhotoCard img="images/相机详情/二级页面产品白底图150/室外机-2/300万像素/Z60室外wifi机.jpg" names="Z60" ulr="Z60"></PhotoCard>
        <PhotoCard img="images/相机详情/二级页面产品白底图150/室外机-2/300万像素/Z82室外wifi机.jpg" names="Z82" ulr="Z82"></PhotoCard>
        <PhotoCard img="images/相机详情/二级页面产品白底图150/室外机-2/300万像素/Z86室外wifi机.jpg" names="Z86" ulr="Z86"></PhotoCard>
      </div>

    </div>
    <!--    室外云台摄像机（200万像素）-->
    <div class="content2">
      <div class="content1_title">
        <div class="content1_title_size">室外云台摄像机（200万像素）</div>
      </div>
      <div class="grid" id="grid">
        <PhotoCard img="images/相机详情/1.WIFI摄像机/室外机详情页（200万像素）/Z81/Z81.jpg" names="Z81" ulr="Z81"></PhotoCard>
      </div>

    </div>
  </div>
</template>

<script>
import PhotoCard from '../../../../../src/components/photo-card.vue'

export default {
  name: "index",
  components: {
    PhotoCard
  },
}
</script>

<style scoped>
.content1 {
  padding-top: 40px;
  width: 80%;
  height: 400px;
  margin: auto;
  /*background: #cd6969;*/
}

.content2 {
  padding-top: 40px;
  width: 80%;
  height: 800px;
  margin: auto;
  /*background: #cd6969;*/
}

.content1_title {
  display: flex;
  justify-content: space-between;
}

.content1_title_size {
  font-size: 20px;
  color: red;
}


#grid {
  /*display: grid;*/
  /*grid-template-columns: repeat(auto-fill, 150px);*/
  /*grid-column-gap: 130px;*/
  /*grid-row-gap: 130px;*/
  align-items: center;
  width: 100%;
  /*justify-content: center;*/
  /*max-width: 800px;*/
  display: flex;
  justify-content: space-evenly;
}

#grid .card {
  background-color: #ccc;
  width: 150px;
  height: 150px;
  transition: all 0.1s ease;
  border-radius: 3px;
  position: relative;
  z-index: 1;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0);
  overflow: hidden;
  cursor: pointer;
}

#grid .card:hover {
  -webkit-transform: scale(2);
  transform: scale(2);
  z-index: 2;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
}

#grid .card:hover img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

#grid .card .reflection {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  left: 0;
  top: 0;
  transition: all 0.1s ease;
  opacity: 0;
  mix-blend-mode: soft-light;
}

#grid .card img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-filter: grayscale(0.65);
  filter: grayscale(0.65);
  transition: all 0.3s ease;
}

.grid {
  padding-top: 100px;
}

p {
  color: red;
  font-size: 100px;
}
</style>